import React from 'react'

const htmlSerializer = (type, node, text, children, key) => {
  var linkid = text?.toLowerCase().replace(/[^A-Za-z0-9]/g, '')
  switch (type) {
    case 'heading1':
      return (
        <h1 key={key} id={linkid}>
          {children}
        </h1>
      )
    case 'heading2':
      return (
        <h2 key={key} id={linkid}>
          {children}
        </h2>
      )
    case 'heading3':
      return (
        <h3 key={key} id={linkid}>
          {children}
        </h3>
      )
    case 'heading4':
      return (
        <h4 key={key} id={linkid}>
          {children}
        </h4>
      )
    case 'heading5':
      return (
        <h5 key={key} id={linkid}>
          {children}
        </h5>
      )
    case 'heading6':
      return (
        <h6 key={key} id={linkid}>
          {children}
        </h6>
      )

    default:
      return null
  }
}

export default htmlSerializer

// var PrismicDOM = require('prismic-dom')
// var Elements = PrismicDOM.RichText.Elements

// exports.htmlSerializer = (type, element, content, children) => {
//   var linkid = children.toLowerCase().replace(/[^a-z0-9_-]/g, '')
//   switch (type) {
//     case Elements.heading1:
//       return `<h1 id='${linkid}'>${children}</h1>`
//     case Elements.heading2:
//       return `<h2 id='${linkid}'>${children}</h2>`
//     case Elements.heading3:
//       return `<h3 id='${linkid}'>${children}</h3>`
//     case Elements.heading4:
//       return `<h4 id='${linkid}'>${children}</h4>`
//     case Elements.heading5:
//       return `<h5 id='${linkid}'>${children}</h5>`
//     case Elements.heading6:
//       return `<h6 id='${linkid}'>${children}</h6>`
//     default:
//       return null
//   }
// }
