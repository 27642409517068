exports.linkResolver = (doc) => {
  var directory = ''
  if (doc && doc.data && doc.data.directory && doc.data.directory !== null) {
    directory = doc.data.directory.toLowerCase().split(' ').join('-') + '/'
  }

  if (doc.uid !== 'index' && doc.type === 'page') return `/${directory}${doc.uid}/`
  if (doc.type === 'blogpost') return `/blog/${doc.uid}/`
  if (doc.type === 'car_park') return `/${directory}${doc.uid}/`
  if (doc.type === 'hotel') return `/${directory}${doc.uid}/`
  if (doc.type === 'lounge') return `/${directory}${doc.uid}/`
  return `/`
}
